import * as React from 'react'
import { PageProps } from 'gatsby'
import { Contact } from '../../components/page'
import { Layout } from '../../components/default-layout'
import { SEO } from '../../components/seo'
import { SpecificLogo } from '../../components/logo'
import { Slider } from '../../components/slider'
import { LocksmithImage } from '../../components/locksmith-image.js'
import { DefaultContainer } from '../../components/containers'

export const Head = () => {
  return (
    <SEO
      article={false}
      title={'Cambio de guardas a domicilio'}
      description={
        'El cambio de guardas se realiza para evitar modificar toda la cerradura, ya que la guarda es solo una pieza que la compone.'
      }
    />
  )
}

const Locksmith: React.FC<PageProps> = () => {
  return (
    <Layout
      theme={'yellow'}
      visible={{}}
      logo={<SpecificLogo>Cerrajería</SpecificLogo>}
    >
      <Slider
        id={'inicio'}
        overlay={0.55}
        business={'Cerrajero profesional'}
        wallpaper={'lockSmithSlider'}
        pageTitle={'Cambio de guardas'}
        subTitle={'Cerrajero las 24 horas del día'}
        pageDescription={
          <>
            El <strong>cambio de guardas</strong> se realiza para evitar
            modificar toda la cerradura, ya que la <strong>guarda</strong> es
            solo una pieza que la compone. Al efectuar el{' '}
            <strong>cambio de guardas</strong> es necesario cambiar la llave
            debido a que las guardas están directamente unidas o conectadas con
            la llave.
          </>
        }
        goTo={'#servicios'}
      />

      <DefaultContainer>
        <section className={'my-14'}>
          <H2>Cambio de guardas a domicilio en Bogotá</H2>
          <p>
            Realizamos el <strong>cambio de todo tipo de cerraduras</strong> o{' '}
            <strong>cambio de guardas</strong>, en cuanto a residencias,
            oficinas, empresas etc, con sus respectivas llaves normales o de
            seguridad, también ofrecemos el servicio de mantenimiento y venta de
            cerraduras. Contamos con técnicos especialistas y profesionales que
            poseen los implementos necesarios para brindar una asistencia
            adecuada. Ofrecemos el servicio de{' '}
            <strong>cambio de guardas en Bogotá</strong>.
          </p>

          <ImageSection
            image={
              <LocksmithImage
                reference={'instalacionDePuertasDeSeguridad'}
                className={'shadow-lg w-full rounded-sm'}
              />
            }
          >
            <H2>¿Qué es una cerradura?</H2>

            <p>
              Una cerradura utiliza un conjunto de obstrucciones, para evitar
              que la cerradura se abra a menos que se inserte la llave correcta.
              La llave tiene guardas o ranuras que se corresponden con las
              obstrucciones en la cerradura, lo que le permite girar libremente
              dentro de la cerradura. Las cerraduras protegidas generalmente se
              utilizan para casos donde la seguridad no es prioritaria, ya que
              una llave maestra bien diseñada puede abrir con éxito una amplia
              variedad de cerraduras protegidas.
            </p>
          </ImageSection>

          <H2>Categorías de cerraduras</H2>
          <H3>Cerradura embutida</H3>
          <p>
            Este modelo es una de las más usadas, ya que esta variedad de
            cerradura se instala de forma incrustada o en el interior de la
            puerta. De este modo sólo se podrá observar el punto por donde se
            puede introducir la llave para poder realizar la apertura o asegurar
            la puerta. Es una excelente opción debido a la seguridad que brindan
            y su manipulación sencilla.
          </p>

          <H3>Cerraduras de sobreponer</H3>
          <p>
            Este tipo de cerraduras se destacan por ir sobrepuesta sobre la
            puerta y no en su interior. En este caso queda todo a la vista,
            tanto el cerradero como la propia cerradura, ya que si van a estar a
            la intemperie deberemos tener en cuenta factores como el deterioro,
            el tamaño, forma y adicional son más fáciles de forzar.
          </p>

          <ImageSection
            isReversed
            image={
              <LocksmithImage
                reference={'cambioDeGuardasConTaladro'}
                className={'shadow w-full rounded'}
                style={{
                  height: '600px',
                }}
              />
            }
          >
            <H2>Elementos de una cerradura</H2>
            <p>
              Existen muchos tipos de cerraduras, no todas tienen las mismas
              partes, así que se el siguiente listado describe una cerradura
              sencilla, para poder entender o conocer sus principales partes.
            </p>

            <H3>Frontal</H3>
            <p>
              Es la parte que se puede observar o ver fácilmente en la
              superficie de la puerta. Cuenta con una serie de tornillos en la
              cerradura, que son los encargados de sostener el bombín y asegurar
              la cerradura a la puerta para que no se caiga.
            </p>

            <H3>Resbalón</H3>
            <p>
              Es la parte que se ubica en el frontal de la cerradura que entra y
              sale. En este elemento se introduce la llave para hacer girar el
              cilindro, y así poder realizar la apertura y cierre de la puerta.
            </p>

            <H3>Bulones</H3>
            <p>
              Esta pieza se retrae en el interior de la caja para permitir que
              la cerradura se abra, solo funciona cuando se ingresa la llave
              adecuada.
            </p>

            <H3>Bombín o cilindro</H3>
            <p>
              Es una de las partes más importantes de la cerradura. En el
              cilindro se introduce la llave para poder ejecutar el mecanismo de
              apertura y cierre de la puerta. Teniendo en cuenta que si el
              cilindro se daña, no es necesario cambiar toda la cerradura,
              bastará con solo reemplazarlo.
            </p>

            <H3>Pomo o manilla</H3>
            <p>
              Es el agarrador o tirador que se ejecuta para abrir la puerta.
            </p>

            <H3>Cerradero</H3>
            <p>
              Es la pieza que se localiza donde también se ubica el resbalón.
              Esta placa de hierro está sujeta a la cerradura en el marco de la
              puerta.
            </p>
          </ImageSection>

          <H2>Diferentes tipos de llaves</H2>
          <p>
            Hay una gran variedad de tipos de llaves dependiendo de su cerradura
            la cual tendrá un mecanismo u otro, a continuación se exponen
            algunas de las llaves más comunes en el mercado.
          </p>

          <H3>Llaves clásicas</H3>
          <p>
            Este tipo de llaves se utilizan muy poco, debido a la forma de la
            cerradura ya que son muy grandes y para nada cómodas de llevar. Se
            utilizaban en las cerraduras grandes como en castillos entre otros.
          </p>

          <H3>Llaves de serreta</H3>
          <p>
            Este tipo de llaves son las más usadas en viviendas, conjuntos
            residenciales, negocios, oficinas etc. Esta llave sobresale por
            tener un borde dentado, que es la clave que permite abrir la
            cerradura.
          </p>

          <H3>Llaves de seguridad</H3>
          <p>
            Esta opción se usa mucho sobre todo en las puertas blindadas.
            Destaca por tener hendiduras y bordes rectos. Son bastante sencillas
            y comunes.
          </p>

          <H3>Llave de pompa</H3>
          <p>
            Son parecidas a las llaves de paleta, este tipo de llave destaca por
            tener dientes en los dos costados de la llave.
          </p>

          <H3>Llaves de paleta o paletón</H3>
          <p>
            Este tipo de llave solo es usada en las cerraduras de borjas en las
            puertas acorazadas. Cuentan con una espiga cilíndrica en el centro
            de la llave y un diente que sale en un lateral, en estas puertas no
            se podrá ver el bombín, ya que está oculto. Solo se podrá ver el
            agujero por donde se ingresa la llave para poder abrir la puerta o
            cerrarla.
          </p>

          <H3>Llaves cruciformes</H3>
          <p>
            Cuentan con una espiga cilíndrica en el centro y suelen ser bastante
            largas. Dependiendo del modelo puede tener más o menos dientes y en
            ocasiones tiene una estructura en forma de cruz.
          </p>
        </section>
      </DefaultContainer>

      <Contact
        id={'contacto'}
        business={'Locksmith'}
        businessName={'Cerrajería'}
        priceRange={'$20.000 - $400.000'}
      />
    </Layout>
  )
}

const H2: React.FC<{ children: string | React.ReactNode }> = props => {
  return (
    <h2 className={'font-semibold text-2xl mt-10 mb-5'}>{props.children}</h2>
  )
}

const H3: React.FC<{ children: string | React.ReactNode }> = props => {
  return <h3 className={'font-medium text-xl mt-6 mb-3'}>{props.children}</h3>
}

const ImageSection: React.FC<{
  image: React.ReactNode
  isReversed?: boolean
  children?: React.ReactNode
}> = ({ image, isReversed, children, ...props }) => {
  return (
    <div
      className={`block md:flex items-center mt-5 md:mt-10 ${
        isReversed ? 'flex-row-reverse' : ''
      }`}
    >
      <div>{children}</div>
      <div
        className={`mt-5 md:mt-0 ml-0 ${isReversed ? '' : 'md:ml-4'} mr-0 ${
          isReversed ? 'md:mr-4' : ''
        } w-full md:w-1/2`}
      >
        {image}
      </div>
    </div>
  )
}

export default Locksmith
